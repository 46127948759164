
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';

export default defineComponent({
  el: '#payment-form-due',
  setup() {
    const organization_address = $('#organization-address').data('address');
    const ONLY_CREDITS_PAYMENT_TYPE = "accrued_credits"

    const state = reactive({
      achAccountName: null,
      achAccountType: null,
      achToken: null,
      bridgeTerms: false,
      poNumber: null,
      Notes:null,
      ccToken: null,
      ccExp: null,
      cvv: null,
      paymentType: 'credit',
      appliedCredit: 0,
      creditAmount: null,
      billing_address: true,
      referenceNo: null,
      address1: organization_address?.street1 ?? "",
      city: organization_address?.city ?? "",
      state: organization_address?.state ?? "",
      zip: organization_address?.zip ?? "",
      extra_fee: 0,
      total_with_credit_card_fee: 0,
      total_without_credit_card_fee: 0,
    });

    onMounted(() => {
      window.addEventListener('message', handleTokenizingResponse);
      calculateTotalWithCreditCardFee();
    });

    const handleTokenizingResponse = (event) => {
      // Next line doesn't allow us to stub this message out in the user tests
      // if (event.origin !== "https://fts.cardconnect.com") return;
      try {
        var payload = JSON.parse(event.data);
        if (payload.hasOwnProperty('ccToken')) {
          console.log('Received Token (cc)');
          state.ccToken = payload.ccToken;
          document.getElementById('credit_card_token').value = state.ccToken;
        } else if (payload.hasOwnProperty('achToken')) {
          console.log('Received Token (ach)');
          state.achToken = payload.achToken;
          document.getElementById('ach_token').value = state.achToken;
        }
      } catch (e) {
        return false;
      }
    }

    const disableForm = computed(() => {
      const termsFieldExists = document.querySelector('input#payment_terms_and_conditions');
      const baseDataInvalid = (!state.bridgeTerms && termsFieldExists) ||
                              !state.address1 ||
                              !state.city ||
                              !state.state ||
                              !(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(state.zip);
      const achDataInvalid = !state.achAccountName ||
                             !state.achToken ||
                             !state.achAccountType;

      const ccDataInvalid = !state.ccToken ||
                            !state.ccExp ||
                            !state.cvv;

      if (state.paymentType == ONLY_CREDITS_PAYMENT_TYPE && creditsCoverTotalCost() && ((termsFieldExists && state.bridgeTerms) || !termsFieldExists) ) {
        return false;
      }else if (state.paymentType == 'credit') {
        return baseDataInvalid || ccDataInvalid;
      }else if(state.paymentType == 'ach'){
        return baseDataInvalid || achDataInvalid;
      }else{
        return true;
      }
    });

    const payingWithCredit = computed(() => {
      return state.paymentType == 'credit';
    });

    const payingWithACH = computed(() => {
      return state.paymentType == 'ach';
    });

    const unableToAddCredit = () => {
      if(isNaN(state.creditAmount) || Number(state.creditAmount) <= 0 || !isNumberUntilTwoDecimalPlaces(state.creditAmount)){
        return true;
      }

      let amount = Number(state.creditAmount);
      return amount > availableCredits() || amount + totalPaid() + state.appliedCredit > totalPurchasedWithLateFee();
    }

    const isNumberUntilTwoDecimalPlaces = (value) => {
      // Obs: All the conversions below are necessary because sometimes in JS: "17.1 * 100 = 1710.0000000000002"
      let number = parseFloat((Number(value) * 100).toFixed(3));
      return Number.isSafeInteger(number);
    }

    const addCredit = () => {
      if (unableToAddCredit()) return true;
      state.appliedCredit += Number(state.creditAmount);
      state.creditAmount = null;
      if (creditsCoverTotalCost()){
        setClassesFromElement("credit_card", "btn disabled");
        setClassesFromElement("e_check", "btn disabled");
        setNewPaymentMethod(ONLY_CREDITS_PAYMENT_TYPE);
      }
      calculateTotalWithoutCreditCardFee();
    }
    
    const setClassesFromElement = (idElement, classes) => {
      let eCheckButton = document.getElementById(idElement);
      if(eCheckButton){
        eCheckButton.className = classes;
      }
    }

    const deleteCredit = () => {
      state.appliedCredit = 0;
      setClassesFromElement("credit_card", "btn");
      setClassesFromElement("e_check", "btn");
      setNewPaymentMethod(null);
      calculateTotalWithoutCreditCardFee();
    }

    const availableCredits = () => {
      var currentCredits = $("#total-credits").data('creditsAvailable');
      return (currentCredits - state.appliedCredit);
    }
    
    const creditsCoverTotalCost = () => {
      return (state.appliedCredit > 0 && calculateTotalWithoutCreditCardFee() == 0);
    }

    const setNewPaymentMethod = (newMethod) => {
      state.paymentType = newMethod;
    }

    const selectCreditCard = () => {
      calculateTotalWithCreditCardFee();
      setNewPaymentMethod('credit');
      state.billing_address = true;
    }

    const formattedTotalWithCreditCard = () => {
      return formatUSAMoneyValue(calculateTotalWithCreditCardFee());
    }

    const calculateTotalWithCreditCardFee = () => {
      return (state.total_with_credit_card_fee = calculateTotalWithoutCreditCardFee() + calculateCreditCardFee());
    }

    const formattedTotalWithoutCreditCard = () => {
      return formatUSAMoneyValue(calculateTotalWithoutCreditCardFee());
    }

    const calculateTotalWithoutCreditCardFee = () => {
      return (state.total_without_credit_card_fee = totalPurchasedWithLateFeeMinusTotalPaid() - state.appliedCredit);
    }

    const totalPurchasedWithLateFeeMinusTotalPaid = () => {
      return totalPurchasedWithLateFee() - totalPaid();
    }

    const totalPurchasedWithLateFee = () => {
      let totalPurchased = Number(document.getElementById("total_charges_with_fees").value);
      let lateFee = Number(document.getElementById("late_fee").value);
      return totalPurchased + lateFee;
    }

    const totalPaid = () => {
      let creditsAppliedBefore = Number(document.getElementById("credits_applied_before").value);
      let cashPayment = Number(document.getElementById("paid_amount").value);
      return creditsAppliedBefore + cashPayment;
    }

    const calculateCreditCardFee = () => {
      let percent_fee = Number(document.getElementById("extra_fee").value);
      return (state.extra_fee = calculateTotalWithoutCreditCardFee() * percent_fee);
    }

    const creditCardFeeFormated = () => {
      return formatUSAMoneyValue(calculateCreditCardFee());
    }

    const formatUSAMoneyValue = (value) => {
      return value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    const selectACHAccount = () => {
      setNewPaymentMethod('ach');
      state.billing_address = true
    }

    const selectCredits = () => {
      state.billing_address = false
      setNewPaymentMethod(creditsCoverTotalCost() ? ONLY_CREDITS_PAYMENT_TYPE : null);     
    }

    return {
      ...toRefs(state),
      handleTokenizingResponse,
      disableForm,
      payingWithCredit,
      payingWithACH,
      unableToAddCredit,
      isNumberUntilTwoDecimalPlaces,
      addCredit,
      setClassesFromElement,
      deleteCredit,
      availableCredits,
      creditsCoverTotalCost,
      setNewPaymentMethod,
      selectCreditCard,
      formattedTotalWithCreditCard,
      calculateTotalWithCreditCardFee,
      formattedTotalWithoutCreditCard,
      calculateTotalWithoutCreditCardFee,
      totalPurchasedWithLateFee,
      totalPaid,
      calculateCreditCardFee,
      creditCardFeeFormated,
      formatUSAMoneyValue,
      selectACHAccount,
      selectCredits,
    }
  },  
  beforeDestroy() {
    window.removeEventListener('message', handleTokenizingResponse);
  },
});
