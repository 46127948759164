/* eslint no-console: 0 */

import { createApp } from 'vue'
import PaymentForm from '../payment/form-due.js'

document.addEventListener('turbolinks:load', () => {
  let formDueDefined = document.getElementById("payment-form-due");
  if(formDueDefined){
    const app = createApp(PaymentForm, {el: '#payment-form-due'});
    app.mount('#payment-form-due');
  }
});

